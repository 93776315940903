import React, {useState} from 'react';

function CarComponent ({img_src,alt_img, logo_src,alt_logo,price,marca,linea,modelo,price_msg,char_img,link, description}) {


  return (
    <div className='card car-card'>
      <div className='container-fluid'>
        
        <div className='row'>
  
          <div className='col'>
            <div className='container'>
              <img
              className='logo-img' 
              src={logo_src} 
              alt={`${alt_img}-logo`} 
              width="100"
              height="100"
              />
            </div>
          </div>
  
          <div className='col d-flex justify-content-end align-items-center'>
            <b><h2 className=''>{modelo}</h2></b>
          </div>
          
        </div>
        
        <div className='row mb-4 mx-2'>
          <div className='container '>
              <h1 className='model-line-title'>{marca} {linea}</h1>
          </div>
        </div>

        <div className='row'>
          <div className='container d-flex justify-content-center align-items-center'>
            <img
              className='car-image' 
              src={img_src} 
              alt={`${alt_img}`}
              width="350"
              height="263"
              style={{ objectFit: 'contain', width: '100%', height: '100%' }}
              />
          </div>
        </div>
        <CarDescription description={description} />
        
        <div className='card-body pb-0'>
          <div className="line-separator"></div>
                                       
          {/* <div className='container my-4'>
            <h2 className='model-tittle'>{marca} {linea} {modelo}</h2>
          </div> */}
          <div className='container mt-2 py-2 hide-mobile-devices'>
            <div className='row'>
              
              <div className='col'>
                <table className="table">
                  <thead className="table-light">
                    <tr>
                       <th scope="col" colSpan={4} className="d-flex align-items-center">
                        <ResidentIcon />
                        <span className="ml-2">Requirements for UAE Residents</span>
                      </th>
                    </tr>

                  </thead>
                  <tbody>
                    <tr>
                      <td>Passport copy</td>
                    </tr>
                    <tr>
                      <td>Valid UAE driving license</td>

                    </tr>
                    <tr>
                      <td>Emirates ID</td>
                    </tr>
                  </tbody>
                </table>
  
              </div>
              <div className='col'>
              <table className="table">
                  <thead className="table-light">
                    <tr>
                      <th scope="col" colSpan={4} className="d-flex align-items-center">
                        <TouristIcon />
                        <span className="ml-2">Requirements for Tourists</span>
                      </th>
                    </tr>

                  </thead>
                  <tbody>
                    <tr>
                      <td>Passport copy</td>
                    </tr>
                    <tr>
                      <td>Valid International driving license</td>

                    </tr>
                    <tr>
                      <td>Visa/Visa stamp</td>
                    </tr>
                  </tbody>
                </table>
                
              </div>

            </div>
          </div>
        </div>
          <div className='container px-0'>
            
            <div className='container-fuid py-3 my-2 hide-web-devices'>
              <h3 className='table-tittle'><CarIcon/> Car Features</h3>
            </div>
            
            <img
                  className='car-charact-img mt-0 mb-4 hide-web-devices'
                  src={char_img}
                  alt={`${marca}-${linea} for rent features!`}
                  width="1024"
                  height="417"
                  style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                  />        
          </div>

          
      </div>
    </div>

  );
};

function CarIcon(){
  return(
    <span className="material-symbols-outlined ">
      <h3 className='mt-2'>directions_car</h3>
    </span>
  )
}

function TouristIcon(){
  return(
    <span className="material-symbols-outlined">
      language
    </span>
  )
}
function ResidentIcon(){
  return(
  <span className="material-symbols-outlined">
    house
  </span>
  )
}
const CarDescription = (props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <div className="container">
        {isExpanded ? (
          <div>
            <p className='description-text'>{props.description}</p>
            <button className="btn btn-primary" onClick={toggleExpand}>
              Hide
            </button>
          </div>
        ) : (
          <div>
            <p className='description-text'>{props.description.slice(0, 400)}...</p>
            <button className="btn btn-primary" onClick={toggleExpand}>
              Show More
            </button>
          </div>
        )}
      </div>
    </div>
  );
};


export default CarComponent;
